import React from 'react'
import "./service.css"
import pic1 from '../../images/pic1.png'
import pic2 from '../../images/pic2.png'
import pic3 from '../../images/pic3.png'
import pic4 from '../../images/pic4.png'
import pic5 from '../../images/pic5.png'
import pic6 from '../../images/pic6.png'


function Service(){
    return(
        <div className='center' id='Service'>
        <div className='what'>
           <h1>WHAT WE DO ?</h1>
           <a>yesssssssssssssssss</a>
        </div>
        <div>
         <div className='card-contents'>

         {/* 1st Row */}
         <div className='row'>
            <div className="cardd">
               <div className="card-icon">
               <img src={pic1} width={50} height={50}></img>
                  <h3 className="card-heading">Credible Design</h3>
               </div>
               <p className="card-para">It is designed to meet the needs of the user and to make the user's experience as efficient and enjoyable as possible.</p>
            </div>

               <div className="cardd">
               <div className="card-icon">
                  <img src={pic2} width={50}  height={50}></img>
                  <h3 className="card-heading">Desirability Framework</h3>
               </div>
               <p className="card-para">To plan, prepare and execute primary sketch for work with a specific function that accomplishes one’s goal and has desired outcome.</p>
               </div>
         </div>
         {/* 2nd Row */}
         <div className='row'>
            <div className="cardd">
               <div className="card-icon">
               <img src={pic3} width={50} height={50}></img>
                  <h3 className="card-heading">Strategical & Sustainable Solution</h3>
               </div>
               <p className="card-para">The process ensuring an effective, maintanable and easy upgradable design based on the business requirement.</p>
            </div>

               <div className="cardd">
               <div className="card-icon">
               <img src={pic4} width={50} height={50}></img>
                  <h3 className="card-heading">View Dimensions</h3>
               </div>
               <p className="card-para">Word, image, time, space, and area are all abstract concepts that can be used to represent different aspects of reality.</p>
               </div>
         </div>
         {/* 3rd row  */}
         <div className='row'>
            <div className="cardd">
               <div className="card-icon">
               <img src={pic5} width={50} height={50}></img>
                  <h3 className="card-heading">Cojoint Plot</h3>
               </div>
               <p className="card-para">Uses multiple processors to execute multiple processes simultaneously. This allows for increased performance and scalability, as multiple processes can be executed in parallel.</p>
            </div>

               <div className="cardd">
               <div className="card-icon">
               <img src={pic6} width={50} height={50}></img>
                  <h3 className="card-heading">Motion Graphics</h3>
               </div>
               <p className="card-para">Creating a series of still images that are rapidly sequenced together to create the illusion of movement</p>
               </div>
         </div>

         </div>
        </div>
        
            
            
    
    </div>
       
       
       
    )
    
}

export default Service;



