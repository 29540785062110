import React from 'react'
import "./benefits.css"
import subimg1 from '../../images/subimg1.png'
import subimg2 from '../../images/subimg2.png'
import subimg3 from '../../images/subimg3.png'
import subimg4 from '../../images/subimg4.png'
import subimg5 from '../../images/subimg5.png'
import subimg6 from '../../images/subimg6.png'
import tick from "../../images/tick.png"


function Benefits() {
    return (

        <div>
            <div className='heading-sub-ben '>
                <h2 className='great'>SUBSCRIPTION BENEFITS</h2>
                <a>---------------</a>
            </div>

            <div className='row-subscription'>
                <div className="cardd-subscription">
                    <img src={subimg1} ></img>
                    <p>Synergistic team</p>
                    <p className='para'>We have a team of talented professionals <br />
                        with a wide range of skills and expertise. <br />
                        We are ready to meet your needs and <br />
                        deliver high-quality results.</p>

                </div>
                <div className="cardd-subscription">
                    <img src={subimg2} ></img>
                    <p>Multi-project management</p>
                    <p className='para'>we can provide separate project <br />
                        management for each project, if you have <br />
                        multiple projects or teams</p>
                </div>
                <div className="cardd-subscription">
                    <img src={subimg3} ></img>
                    <p>Collaborate with your team</p>
                    <p className='para'>You can invite your product managers, <br />
                        developers, and other stakeholders to<br />
                        participate in the process.</p>

                </div>
            </div>
            <div className='row-subscription'>
                <div className="cardd-subscription">
                    <img src={subimg4} ></img>
                    <p>Harmonious cooperation</p>
                    <p className='para'>You pay a fixed monthly fee, and we will<br />
                        not add any additional costs.</p>

                </div>
                <div className="cardd-subscription">
                    <img src={subimg5} ></img>
                    <p>Reliable results</p>
                    <p className='para'>We ensure the quality of every deliverable,<br />
                        from the initial drafts to the final handoff.</p>

                </div>
                <div className="cardd-subscription">
                    <img src={subimg6} ></img>
                    <p>Cost-effective pricing</p>
                    <p className='para'>We are a flexible team that is always<br />
                        willing to help you succeed.</p>

                </div>
            </div>


            <div className='heading-sub '>
                <h2 className='great'>A GREAT FIT FOR</h2>
                <a>yesssssssssssss</a>
            </div>

            <div>
                <div className='great-fit-card'>
                    {/* <div className='great-fit-col'> */}

                        <div className='great-fit-row'>
                            <img src={tick} className='tick-img'></img>
                            <div className='great-fit-para'>
                                <p className='para-line'>Product teams that need professional design</p>
                            </div>
                        </div>

                        <div className='great-fit-row'>
                            <img src={tick} className='tick-img'></img>
                            <div className='great-fit-para'>
                                <p className='para-line'>Design teams that need support with tactical tasks</p>
                            </div>
                        </div>

                        <div className='great-fit-row'>
                            <img src={tick} className='tick-img'></img>
                            <div className='great-fit-para'>
                                <p className='para-line'>Digital agencies that need to tackle the design process for different clients</p>
                            </div>
                        </div>
                        <div className='great-fit-row'>
                            <img src={tick} className='tick-img'></img>
                            <div className='great-fit-para'>
                                <p className='para-line'>Tech startups and companies planning new products and features</p>
                            </div>
                        </div>

                    </div>

         
                </div>
            </div>

        


     


        // </div>
    )
}

export default Benefits;