import React from 'react'
import "./process.css"
import mobimg1 from "../../images/mobieimg1.png"
import mobimg2 from "../../images/mobileimg2.png"
import des1 from "../../images/Des1.png"
import des2 from "../../images/Des2.png"
import des3 from "../../images/Des3.png"
import designimg1 from "../../images/designhalf1.png"
import designimg2 from "../../images/designhalf2.png"
import workimg1 from "../../images/workimg1.png"
import workimg2 from "../../images/workimg2.png"
import workimg3 from "../../images/workimg3.png"
import workimg4 from "../../images/workimg4.png"
import workimg4new from "../../images/workimg4new.png"
import workimg5 from "../../images/workimg5.png"
import workimg6 from "../../images/workimg7.png"
import frame1 from "../../images/Frame1.png"
import frame2 from "../../images/Frame2.png"
import frame3 from "../../images/Frame3.png"
import framelogo from "../../images/framelogo.png"



function Process() {
    return (
        <div id='Process'>
            <div className='heading-process'>
                <h1>HOW WE WORK WITH YOUR TEAM</h1>
                <a>yesssssssssssssssss</a>
                <p className='title'>Project Management</p>
                <p className='para-project'>We collaborate with you and your product team to define the most important priorities<br />
                    and projects, and then we break them down into well-defined tasks. This allows you to <br />
                    track progress, time spent, and updates in a single board. We can tackle one or multiple <br />
                    tasks at a time, depending on the complexity, to deliver high-quality results.</p>
            </div>

            <div className='center-content'>
                <p className='heading-mobile-app'>MOBILE APP</p>
                <div className='mob-card'>
                    <div className='mob-img-1-div'>
                        <img src={mobimg1} className='mob-img-1'></img>
                    </div>
                    <div>
                        <img src={mobimg2} className='mob-img-2'></img>
                    </div>
                </div>
                <p className='heading-des-app'>DESIGN SYSTEM</p>
                <div className='des-card'>
                    <div className='des-inside-card'>

                    <img src={des1} className='des-image'></img>
                    <img src={des2} className='des-image'></img>
                    <img src={des3} className='des-image'></img>
                    </div>

                </div>
            </div>
            <div className='heading-process'>
                <p className='title'>Communication</p>
                <p className='communication-para'>Each project and topic will have a dedicated space where we can chat, share comments, resources, and<br />
                    updates. This helps to keep communication clear and effective.</p>
            </div>

            <div>
                <div className='frame-card'>
                    <div className='frame-inside-card'>
                     
                    <img src={framelogo} className='frame-logo'></img>
                    <img src={frame1} width='70%' height='10%' className='frame1'></img>
                    <img src={frame2} width='70%' height='10%' className='frame2'></img>
                    <img src={frame3} width='70%' height='10%' className='frame3'></img>
                </div>
                
            </div>

            <div className='heading-process'>

                <p className='title'> Design and Handoff</p>
                <p className='design-down-para'>Your work will be stored in a dedicated Figma project. We will start the design process from scratch,<br />
                    document the components and design system, create high-fidelity pixel-perfect mockups and prototypes,<br />
                    and then handoff the project to your developers. The handoff will include all the specifications and <br />
                    properties from the screens and flows, so your developers can easily implement the project.</p>
            </div>

            <div className='design-card'>
                <img src={designimg1} width='60%' height='80%' className='design-card1'></img>
                <img src={designimg2} height='80%' width='40%' className='design-card2'></img>

            </div>

            <div className='heading-sub-ben '>
                <h2 className='great'>OUR WORK</h2>
                <a>yesssssss</a>
            </div>
            <div className='work-card'>
                <div className='work-card-first-row'>
                    <img src={workimg1} width='30%' height='50%' className='first-row'></img>
                    <img src={workimg2} height='50%' width='30%' className='first-row'></img>
                    <img src={workimg3} width='30%' height='50%' className='first-row'></img>
                </div>
                <div className='work-card-second-row'>
                    <img src={workimg4} height='30%' width='17%' className='second-row'></img>
                    <img src={workimg4new} height='30%' width='17%' className='second-row'></img>
                    <img src={workimg5} width='30%' height='50%' className='second-row'></img>
                    <img src={workimg6} height='50%' width='20%' className='second-row'></img>
                </div>

            </div>
        </div>
        </div>
    )


}
    


export default Process;