import react from 'react'
import "./footer.css"
import logo from "../../images/log.png"
import tick from "../../images/tick.png"

function Footer() {
    return (
        <div className='footer-card'>
            <div className='footer-logo'>
                <img src={logo}></img>
            </div>

            <div>
                <div className='footer-col'>

                    <div className='footer-row'>
                        <i className='fa fa-phone'></i>
                       
                        <div className='footer-para'>
                            <p className='footer-line1'>+91 996-222-8323</p>
                        </div>
                    </div>

                    <div className='footer-row'>
                         <i className='fa fa-envelope'></i>
                        
                        <div className='footer-para'>
                        <p className='footer-line'> <a href="mailto:info@digamend.com" >info@digamend.com</a></p>
                        </div>
                    </div>

                    <div className='footer-row'>
                    <i className='fa fa-envelope'></i>
                        
                        <div className='footer-para'>
                        <p className='footer-line'> <a href="mailto:hr@digamend.com" >hr@digamend.com</a></p>
                        </div>
                    </div>
                    <div className='footer-row'>
                    <i className='fa fa-envelope'></i>
                        
                        <div className='footer-para'>
                       
                            <p className='footer-line'> <a href="mailto:sales@digamend.com" >sales@digamend.com</a></p>
                        </div>
                    </div>

                </div>
            </div>

           
        </div>
    )
}

export default Footer;