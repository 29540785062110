import React from 'react'
import "./home.css"
import img1 from "../../images/img1.png"
import img2 from "../../images/img2.png"
import img3 from "../../images/img3.png"


let Home = ()=>{
return(
    <div className="home" id='Home'>
        <div className="h1">PULL THE RABBIT OUT OF<br/> THE HAT - FOR<br/> PRODUCT DESIGN TEAM</div>
        <div className="p"> Monthly Subscription for product teams that<br/>
need high-quality user interface experience quickly</div>
        <div>
        {/* <a href="MSTeams:/l/chat/0/0?users=info@digamend.com">Teams Chat</a> */}
        <a href="mailto:abisenthil2904@gmail.com"> <button className='home-btn'>Write To Us<i className='fa fa-angle-right'></i> </button></a>
        <a href="tel:+91 996-222-8323" className='talktous'> <button className='home-btn'>Talk To Us <i className='fa fa-angle-right'></i> </button></a>
           
        </div>

        <div>
            <div className='card'>
                <div className='inner-card'>
                <div className='icon'>
                    <img src={img1}></img>
                </div>
                <div className='heading'>
                    <h3>Halt / Quit Anytime</h3>
                    <p>No contracts, No Stress</p>
                </div>
                </div>

                <div className='inner-card'>
                <div className='icon'>
                <img src={img2}></img>
                    </div>
                <div className='heading'>
                    <h3>Monthly Price Ceiling</h3>
                    <p>With no surprises</p>
                </div>
                </div>
                <div className='inner-card'>
                <div className='icon'>
                <img src={img3}></img>
                </div>
                <div className='heading'>
                    <h3>Seamless Collaboration</h3>
                    <p>With an agile design team</p>
                </div>
                </div>

            </div>
        </div>
    </div>
  
);
}
export default Home;