import React from 'react'
import "./pricing.css"
import tick from "../../images/tick.png"

function Pricing() {
    return (
        <div  id='Pricing'>
            <div className='heading-pricing'>
                <h1>PRICING</h1>
                <a>yessssssss</a>

                <p className='pricing-para'>Pay for the no of hour you utilize the resource.<br />
                    With a flexible rate of $25 /hour for Senior Associate & $20/hour for Associate.<br />
                    Minimum 30 hours/ month billing is required.
                </p>
            </div>

            <div className='price-card'>
                {/* <div className='pricing-whole'> */}
                {/* <div className='price'> */}
              
                <div className='price-card-left'>

                    <div className='words'>
                        
                        <div>
                            <p>Senior Associate</p>
                            <div className='money'>
                                <h3>$1,825 USD</h3>
                                <p>/month</p>
                            </div>
                            <p>(Upto 80 hours per month)</p>
                        </div>

                        <div>
                            <p>Associate</p>
                            <div className='money'>
                                <h3>$1,525 USD</h3>
                                <p>/month</p>
                            </div>
                            <p>(Upto 80 hours per month)</p>
                        </div>

                        <div>
                        <a href="tel:+91 996-222-8323" className='talktous'> <button className='home-btn'>Talk To Us <i className='fa fa-angle-right'></i> </button></a>
                        </div>
                    </div>

                </div>

                <div className='price-card-right'>

                    {/* <div className='points-col'> */}

                        <div className='points-row'>
                            <img src={tick} className='tick-img-pricing'></img>
                            <div className='points-para'>
                                <p className='para-line'>Daily mentoring and guidance by the lead team</p>
                            </div>
                        </div>

                        <div className='points-row'>
                            <img src={tick} className='tick-img-pricing'></img>
                            <div className='points-para'>
                                <p className='para-line'>One dedicated resource for your team for the hours</p>
                            </div>
                        </div>

                        <div className='points-row'>
                            <img src={tick} className='tick-img-pricing'></img>
                            <div className='points-para'>
                                <p className='para-line'>Access to all our services</p>
                            </div>
                        </div>
                        <div className='points-row'>
                            <img src={tick} className='tick-img-pricing'></img>
                            <div className='points-para'>
                                <p className='para-line'>Multiple projects and products allowed</p>
                            </div>
                        </div>

                        <div className='points-row'>
                            <img src={tick} className='tick-img-pricing'></img>
                            <div className='points-para'>
                                <p className='para-line'> Multiple Iterations allowed</p>
                            </div>
                        </div>
                    </div>

                </div>
                </div>

    //  </div>

    //  </div>
    )
}

export default Pricing;
