import logo from "../../images/log.png"
import "./navbar.css";
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';


function Navbar() {

    const [showNavbar, setShowNavbar] = useState(false);

    return (
        <div className="header">
            <img src={logo} className="logo" />
            <div>
                <button className='nav-btn' onClick={() => setShowNavbar(!showNavbar)}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <ul className={showNavbar ? "navbar show" : "navbar"}>
                    <li>
                        <Link to="Home" spy={true} smooth={true} offset={50} duration={500} > Home</Link>
                    </li>
                    <li>
                        <Link to="Service" spy={true} smooth={true} offset={50} duration={500} >Service</Link>
                    </li>
                    <li>
                        <Link to="Process" spy={true} smooth={true} offset={50} duration={500} >Process</Link>
                    </li>
                    <li>
                        <Link to="Pricing" spy={true} smooth={true} offset={50} duration={500} >Pricing</Link>
                    </li>


                </ul>
            </div>
        </div>

    )
}

export default Navbar;

