

import Navbar from './components/navbar/navbar.js'
import Home from './components/home/home.js'
import Service from './components/service/service.js'
import Process from './components/process/process.js'
import Benefits from './components/benefits/benefits.js'
import Pricing from './components/pricing/pricing.js'
import Footer from './components/footer/footer.js'
import Chatbox from './components/chatbox/chatbox.js'

function App() {
  return (
    <>
    <Navbar />
    <Home/>
    <Service/>
    <Process/>
    <Benefits/>
    <Pricing/>
    <Footer/>
    {/* <Chatbox/> */}
    </>

  );
}

export default App;
